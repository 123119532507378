import "./style.css";
import * as THREE from "three";
import * as dat from "dat.gui";
import { gsap } from "gsap";
import { TTFLoader } from "three/examples/jsm/loaders/TTFLoader";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";

let raycaster, mouse;
let currentIntersection = null;

let isLoaded = false;

// Texture loader
const loader = new THREE.TextureLoader();
const height = loader.load("height.png");
const heightMars = loader.load("heightMars.png");
const heightMercury = loader.load("heightMercury.png");
const texture = loader.load("/texture.jpg");
const moonTexture = loader.load("/moon.jpg");
const marsTexture = loader.load("/mars.jpg");
const mercuryTexture = loader.load("/mercury.jpg");
const saturnTexture = loader.load("/saturn.jpg");
const venusTexture = loader.load("/venus.jpg");
const earthTexture = loader.load("/earth.jpg");
const alpha = loader.load("alpha.png");

const normalTexture = loader.load("normal.jpg");

const fontLoader = new FontLoader();
const ttfLoader = new TTFLoader();

var textMichael = document.getElementById("section-1");
textMichael.style.fontSize = 7 + "vmin";

let textMesh;
let textMeshDescription;

ttfLoader.load("fonts/BebasNeue-Regular.ttf", (json) => {
  // First parse the font.
  const jetBrainsFont = fontLoader.parse(json);

  // Use parsed font as normal.
  const textGeometry = new TextGeometry("Hi! I'm \nMichael", {
    height: 0,
    size: 0.3,
    font: jetBrainsFont,
  });

  const textMaterial = new THREE.MeshPhongMaterial({ color: 0xffeded });
  textMesh = new THREE.Mesh(textGeometry, textMaterial);
  textMesh.position.x = -2.9;
  textMesh.position.y = 0.25;

  const textGeometry2 = new TextGeometry(
    "I'm a Software Developer\nbased in Ann Arbor Michigan",
    {
      height: 0,
      size: 0.08,
      font: jetBrainsFont,
    }
  );

  const textMaterial2 = new THREE.MeshPhongMaterial({ color: "lightblue" });
  textMeshDescription = new THREE.Mesh(textGeometry2, textMaterial2);
  textMeshDescription.position.x = -2.9;
  textMeshDescription.position.y = -0.25;
});

// Debug
// const gui = new dat.GUI();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

const loadingScreen = new THREE.Mesh(
  new THREE.PlaneBufferGeometry(0.3, 0.3, 32, 32),
  new THREE.MeshStandardMaterial({
    color: "white",
  })
);

loadingScreen.name = "Loading";

loadingScreen.scale.set(0, 0);

scene.add(loadingScreen);

// Objects
const geometry = new THREE.PlaneBufferGeometry(5, 2, 64, 64);

// Materials
const moonMaterial = new THREE.MeshStandardMaterial({
  color: "grey",
  map: moonTexture,
  displacementMap: height,
  displacementScale: 0.5,
  alphaMap: alpha,
  transparent: false,
  depthTest: false,
});

const moonPlane = new THREE.Mesh(geometry, moonMaterial);
moonPlane.name = "Moon Plane";
// moonPlane.rotation.x = 181;
moonPlane.position.x = 0;
moonPlane.position.y = -0.2;
moonPlane.position.z = -100;

moonPlane.material.opacity = 0;

const moon = new THREE.Mesh(
  new THREE.SphereGeometry(0.3, 32, 32),
  new THREE.MeshStandardMaterial({
    map: moonTexture,
  })
);

moon.position.z = 0;
moon.position.y = 0;
moon.position.x = 7;

moon.scale.set(2, 2);

moon.material.transparent = true;

moon.name = "Moon";

scene.add(moon);

const mars = new THREE.Mesh(
  new THREE.SphereGeometry(0.3, 32, 32),
  new THREE.MeshStandardMaterial({
    map: marsTexture,
  })
);

mars.position.z = 0;
mars.position.y = -2.5;
mars.position.x = 7;

mars.name = "Mars";

// scene.add(mars);

const mercury = new THREE.Mesh(
  new THREE.SphereGeometry(0.3, 32, 32),
  new THREE.MeshStandardMaterial({
    map: mercuryTexture,
  })
);

mercury.position.z = 0;
mercury.position.y = -6.5;
mercury.position.x = -7;

mercury.name = "Mercury";

// scene.add(mercury);

var group = new THREE.Object3D();

// 半径
var radius = 300;
var separation = 14;

for (var s = 0; s <= 180; s += separation) {
  // 0 <= s <= 180, なんで -1 <= Math.cos(radianS) <= 1
  // なんで zが -radius <= z <= radius
  var radianS = (s * Math.PI) / 180;
  var pZ = radius * Math.cos(radianS);

  // 円に沿って点描く
  for (var t = 0; t < 360; t += separation) {
    // 角度をラジアンに
    var radianT = (t * Math.PI) / 180;
    // 点の座標を計算
    // sin(radianS)は0→1→0の順で変化する
    // radius * sin(radianS)は0→200→0になる
    var pX = radius * Math.sin(radianS) * Math.cos(radianT);
    var pY = radius * Math.sin(radianS) * Math.sin(radianT);

    var geo = new THREE.SphereGeometry(0.4, 6, 6);
    var material = new THREE.MeshBasicMaterial({
      color: "#ffeded",
    });
    var mesh = new THREE.Mesh(geo, material);
    mesh.position.x = pX;
    mesh.position.y = pY;
    mesh.position.z = pZ;
    group.add(mesh);
  }
}

group.scale.set(0.02, 0.02, 0.02);

group.position.z = -2;

scene.add(group);

const marsMaterial = new THREE.MeshStandardMaterial({
  color: "grey",
  map: marsTexture,
  displacementMap: heightMars,
  displacementScale: 0.3,
  alphaMap: alpha,
  transparent: true,
  depthTest: false,
});

const marsPlane = new THREE.Mesh(geometry, marsMaterial);

marsPlane.name = "Mars Plane";
// scene.add(marsPlane);
marsPlane.rotation.x = 181;
marsPlane.position.y = -4;
marsPlane.position.x = 7;

const mercuryMaterial = new THREE.MeshStandardMaterial({
  color: "grey",
  map: mercuryTexture,
  displacementMap: heightMercury,
  displacementScale: 0.3,
  alphaMap: alpha,
  transparent: true,
  depthTest: false,
});

const mercuryPlane = new THREE.Mesh(geometry, mercuryMaterial);
mercuryPlane.name = "Mercury Plane";
// scene.add(mercuryPlane);
mercuryPlane.rotation.x = 181;
mercuryPlane.position.y = -8;
mercuryPlane.position.x = -7;

// Mesh

// Lights

const pointLight = new THREE.PointLight(0xffffff, 2);
pointLight.position.x = 6;
pointLight.position.y = 10;
pointLight.position.z = 10;
scene.add(pointLight);

// gui.add(pointLight.position, "x");
// gui.add(pointLight.position, "y");
// gui.add(pointLight.position, "z");

const ambientLight = new THREE.AmbientLight(0xffffff, 3);

// scene.add(ambientLight);

// const col = { color: "#00ff00" };
// gui.addColor(col, "color").onChange(() => {
//   pointLight.color.set(col.color);
// });
/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 3;
scene.add(camera);

document.addEventListener("mousedown", onDocumentMouseDown, false);

const impactListener = new THREE.AudioListener();
camera.add(impactListener);

// create a global audio source
const impactSound = new THREE.Audio(impactListener);

// load a sound and set it as the Audio object's buffer
const impactAudioLoader = new THREE.AudioLoader();

function onDocumentMouseDown(event) {
  mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
  mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
  raycaster.setFromCamera(mouse, camera);
  const intersects = raycaster.intersectObject(scene, true);
  if (intersects.length > 0) {
    currentIntersection = intersects[0].object;
    if (isLoaded && currentIntersection.name === "Loading") {
      gsap.to(loadingScreen.material, {
        duration: 0.8,
        opacity: 0,
        delay: 1.5,
      });
      gsap.to(loadingScreen.position, { duration: 3, x: -2.9, delay: 0.5 });
      gsap.to(loadingScreen.position, { duration: 1, x: -100, delay: 2.5 });

      gsap.to(moon.position, { duration: 1.65, x: 0, delay: 1 });

      gsap.to(group.rotation, { duration: 2, y: 1.2, delay: 0.5 });
      // create an AudioListener and add it to the camera

      impactAudioLoader.load("audio/impact.mp3", function (buffer) {
        impactSound.setBuffer(buffer);
        impactSound.setLoop(false);
        impactSound.setVolume(0.2);
        impactSound.play();
      });

      gsap.to(textMichael.style, {
        duration: 1.65,
        fontSize: 3 + "vmin",
        delay: 0.5,
      });

      gsap.to(textMichael.style, {
        duration: 1.65,
        marginLeft: -900 + "px",
        delay: 0.5,
      });

      gsap.to(textMichael.style, {
        duration: 0.05,
        fontSize: 7 + "vmin",
        delay: 2.2,
      });

      setTimeout(() => {
        textMichael.innerHTML = "";
        textMichael.style.marginLeft = 75 + "px";
        scene.add(textMesh, textMeshDescription);
      }, 2400);
    } else if (currentIntersection.name === "Moon") {
      gsap.to(moon.position, { duration: 1, z: 3 });
      moon.material.opacity = 1;
      gsap.to(textMesh.position, { duration: 1, y: 1.5 });
      gsap.to(textMeshDescription.position, { duration: 1, y: 1 });
      gsap.to(moonPlane.material, { duration: 1, opacity: 1, delay: 0.5 });
      gsap.to(moonPlane.position, { duration: 0.2, z: 2, delay: 0.5 });
      gsap.to(moonPlane.rotation, { duration: 2, x: -1, delay: 0.5 });
      gsap.to(group.rotation, { duration: 1, y: 3.2 });
      impactAudioLoader.load("audio/deep-air.wav", function (buffer) {
        impactSound.setBuffer(buffer);
        impactSound.setLoop(false);
        impactSound.setVolume(0.2);
        impactSound.play();
      });
      setTimeout(() => {
        scene.add(moonPlane);
      }, 700);
    } else if (currentIntersection.name === "Moon Plane") {
      // gsap.to(moon.position, { duration: 1, z: 0 });
      // gsap.to(textMesh.position, { duration: 1, y: 0.25 });
      // gsap.to(textMeshDescription.position, { duration: 1, y: -0.25 });
      // gsap.to(moonPlane.material, { duration: 1, opacity: 0, delay: 0.5 });
      // gsap.to(moonPlane.position, { duration: 0.2, z: -100 });
      // gsap.to(group.rotation, { duration: 1, y: 1.2 });
      gsap.to(moonPlane.position, { duration: 2.5, y: -1.5 });
      gsap.to(camera.position, { duration: 2, z: 0.9 });
      impactAudioLoader.load("audio/deep-air.wav", function (buffer) {
        impactSound.setBuffer(buffer);
        impactSound.setLoop(false);
        impactSound.setVolume(0.2);
        impactSound.play();
      });
    }
  } else {
    if (currentIntersection !== null) {
      currentIntersection = null;
    }
  }
}

const listener = new THREE.AudioListener();
camera.add(listener);

// create a global audio source
const sound = new THREE.Audio(listener);

// load a sound and set it as the Audio object's buffer
const audioLoader = new THREE.AudioLoader();

audioLoader.load(
  "audio/ambient.mp3",
  function (buffer) {
    sound.setBuffer(buffer);
    sound.setLoop(true);
    sound.setVolume(0.2);
    sound.play();
  },
  function (xhr) {
    loadingScreen.scale.set(xhr.loaded / xhr.total, xhr.loaded / xhr.total);
    textMichael.innerHTML =
      ((xhr.loaded / xhr.total) * 100).toFixed(2) + "% loaded";
    if ((xhr.loaded / xhr.total) * 100 + "% loaded" === "100% loaded") {
      textMichael.innerHTML = "Explore";
      textMichael.style.fontSize = 7 + "vmin";
      isLoaded = true;
    }
  }
);

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */

document.addEventListener("mousemove", animateTerrain);

let mouseY = 0;
let mouseX = 0;
function animateTerrain(event) {
  mouseY = event.clientY;
  mouseX = event.clientX;
}

// /**
//  * Scroll
//  */
let scrollY = window.scrollY;

window.addEventListener("scroll", () => {
  scrollY = window.scrollY;
});

let scrollMovement = 0;
let scrollingAllowed = true;

document.addEventListener("mousewheel", (event) => {
  if (scrollMovement !== event.deltaY) {
    if (scrollingAllowed) {
      scrollMovement = event.deltaY;
      if (scrollMovement > 0) {
        triggerNextScene("back");
      } else {
        triggerNextScene("front");
      }
      scrollingAllowed = false;
    }
    setTimeout(() => {
      scrollingAllowed = true;
    }, 5000);
  }
});

let currentScene = 1;

const triggerNextScene = (direction) => {
  if (direction === "back") {
    if (currentScene > 1) {
      currentScene -= 1;
    }
  } else {
    if (currentScene < 5) {
      currentScene += 1;
    }
  }
};

// function lerp(x, y, a) {
//   return (1 - a) * x + a * y;
// }

// // Used to fit the lerps to start and end at specific scrolling percentages
// function scalePercent(start, end) {
//   return (scrollPercent - start) / (end - start);
// }

// const animationScripts = [];

// //add an animation that flashes the cube through 100 percent of scroll
// animationScripts.push({
//   start: 0,
//   end: 101,
//   func: () => {},
// });

// //add an animation that moves the cube through first 40 percent of scroll
// animationScripts.push({
//   start: 5,
//   end: 40,
//   func: () => {
//     moonPlane.position.x = lerp(0, -7, scalePercent(5, 40));
//     moon.position.x = lerp(-1, -7, scalePercent(5, 40));
//   },
// });

// //add an animation that rotates the cube between 40-60 percent of scroll
// animationScripts.push({
//   start: 20,
//   end: 55,
//   func: () => {
//     marsPlane.position.x = lerp(7, 0, scalePercent(20, 55));
//     mars.position.x = lerp(7, 1, scalePercent(20, 55));
//   },
// });

// animationScripts.push({
//   start: 55,
//   end: 80,
//   func: () => {
//     marsPlane.position.x = lerp(0, -7, scalePercent(55, 80));
//     mars.position.x = lerp(1, -7, scalePercent(55, 80));
//   },
// });

// //add an animation that moves the camera between 60-80 percent of scroll
// animationScripts.push({
//   start: 70,
//   end: 90,
//   func: () => {
//     mercuryPlane.position.x = lerp(7, 0, scalePercent(70, 90));
//     mercury.position.x = lerp(7, 1, scalePercent(70, 90));
//   },
// });

// //add an animation that auto rotates the cube from 80 percent of scroll
// animationScripts.push({
//   start: 80,
//   end: 101,
//   func: () => {
//     console.log("80100");
//   },
// });

// function playScrollAnimations() {
//   animationScripts.forEach((a) => {
//     if (scrollPercent >= a.start && scrollPercent < a.end) {
//       a.func();
//     }
//   });
// }

let scrollPercent = 0;

document.body.onscroll = () => {
  //calculate the current scroll progress as a percentage
  scrollPercent =
    ((document.documentElement.scrollTop || document.body.scrollTop) /
      ((document.documentElement.scrollHeight || document.body.scrollHeight) -
        document.documentElement.clientHeight)) *
    100;
  document.getElementById("scrollProgress").innerText =
    "Scroll Progress : " + scrollPercent.toFixed(2);
};

window.addEventListener("wheel", onMouseWheel);

let y = 0;
let position = 0;

function onMouseWheel(event) {
  y = event.deltaY * 0.0007;
}

const targetPositionY = 0.5;
let goingUp = true;

const clock = new THREE.Clock();

raycaster = new THREE.Raycaster();
mouse = new THREE.Vector2();

document.addEventListener("mousemove", onMouseMove);

const hoverListener = new THREE.AudioListener();
camera.add(hoverListener);

// create a global audio source
const hoverSound = new THREE.Audio(hoverListener);

// load a sound and set it as the Audio object's buffer
const hoverAudioLoader = new THREE.AudioLoader();

function onMouseMove(event) {
  mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
  mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
  raycaster.setFromCamera(mouse, camera);
  const intersects = raycaster.intersectObject(scene, true);
  if (intersects.length > 0) {
    currentIntersection = intersects[0].object;
    if (currentIntersection.name === "Moon") {
      gsap.to(moon.material, { duration: 0.5, opacity: 0.7 });
    }
    if (isLoaded && currentIntersection.name === "Loading") {
      loadingScreen.material.color.set("grey");
    }
  } else {
    if (currentIntersection !== null) {
      gsap.to(moon.material, { duration: 0.5, opacity: 1 });
      if (currentIntersection.name === "Loading") {
        loadingScreen.material.color.set("white");
      }
      currentIntersection = null;
    }
  }
}

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update objects
  // sphere.rotation.y = .5 * elapsedTime

  // position -= y;

  // y *= 0.9;

  camera.position.y = scrollY * -0.0036;

  // moonPlane.rotation.z = 0.4 * elapsedTime;

  // moonPlane.material.displacementScale = 0.3 + mouseY * 0.0005;

  marsPlane.rotation.z = 0.4 * elapsedTime;

  marsPlane.material.displacementScale = 0.3 + mouseY * 0.0005;

  mercuryPlane.rotation.z = 0.4 * elapsedTime;

  mercuryPlane.material.displacementScale = 0.3 + mouseY * 0.0005;

  camera.position.y = mouseY * 0.0004 - 0.13;
  camera.position.x = mouseX * 0.0004 - 0.25;

  // mikePlane.position.y = scrollY * 0.0045;

  // moonPlane.position.y = scrollY * 0.0045;

  // marsPlane.position.y = scrollY * 0.0045 + -4.5;

  // mercuryPlane.position.y = scrollY * 0.0045 + -9;

  moon.rotation.z = 0.2 * elapsedTime;

  loadingScreen.rotation.z = 0.2 * elapsedTime;

  mars.rotation.z = 0.2 * elapsedTime;

  mercury.rotation.z = 0.2 * elapsedTime;

  // var rotateX = 0.0009 * scrollY;
  // var rotateY = 0.001 * scrollY;
  // var rotateZ = 0.0002 * scrollY;
  // group.rotation.set(rotateX, rotateY, rotateZ);

  // playScrollAnimations();

  // moon.position.x = -0.019 * scrollY;
  // moon.position.y = 0.01 * scrollY + 6.5;

  // mars.position.x = -0.025 * scrollY + 25;
  // mars.position.y = 0.01 * scrollY - 3.5;

  // mercury.position.x = -0.025 * scrollY + 50;
  // mercury.position.y = 0.012 * scrollY - 18;

  if (group.position.y < targetPositionY && goingUp) {
    group.position.y += 0.002; // You decide on the increment, higher value will mean the objects moves faster
    loadingScreen.position.y += 0.0007;
  }

  if (group.position.y.toPrecision(3) == targetPositionY) {
    goingUp = false;
  }

  if (group.position.y.toPrecision(3) <= -0.5) {
    goingUp = true;
  }

  if (!goingUp) {
    group.position.y += -0.002;
    loadingScreen.position.y += -0.0007;
  }

  // Update Orbital Controls
  // controls.update()

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
